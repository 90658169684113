@import 'themes-vars';
@import 'plugins/plugins';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body { font-family: 'Poppins', sans-serif !important; background: #D2D2D2 !important; }
.icon-breadcrumb { margin-right: 5px; }
.link-breadcrumb { font-size: 0.75rem; display: flex; text-decoration: none; }

/* card */

header { background-image: linear-gradient(to right, #725792, #644c80, #56426f, #48385e, #3b2e4d); }
.MuiGrid-root.common-bg { background: url(../images/bg-common.png); overflow-x: hidden; background-attachment: fixed; background-size: cover; }
.MuiGrid-root.home-tabs.home-tabs-black:after, .MuiGrid-root.home-tabs:after { content: ""; bottom: -20px; left: 0; width: 100%; height: 24px; right: 0; position: absolute; }
.MuiGrid-root.home-tabs.innerhome:after { background: url('../images/Shadow.png') no-repeat 50%; background-size: 93%; }

.MuiGrid-root.home-tabs:after { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAdEAAAAVCAYAAAAZzdSNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARlJREFUeNrs3CFPw0AUB/B7yBnwgGUfBAvfEY1lFovAz0yQELC1M4jbNXQTuHW9XkZ+v+SlS5Zcm3fin16aFznnx5TSQ6rnqtRlAoA2PmstfKG3ACBEAUCIAoAQBYB/HqJbbQCAcSH6pQ0AMC5Ev7UBAEaEaET0x7mvWgEAR9nuPyxaJce6AHCM59j/yjkv0u/kovuJb2JiEQAtTT2xqCv1FBGb+PvPEKY3pW5LLSa42fVQANDC+0Tr9Ce2XQnPw8lt1H7yEsrLcrmzhwC0UELvpdbahi0AgBAFACEKAEIUAIToaczmBaCVqhk0R4h29hCARqpmUPUQHcYKfthHAGb2U2pz7m+ifZCuk7GCAMwboG/Di1w1OwEGAIPXKk5nuDgLAAAAAElFTkSuQmCC) no-repeat 50%; background-size: 93%; }
.card-header { font-weight: 600 !important; font-size: 15px; }
.apexcharts-legend-marker { margin-right: 8px !important; }
.right-button.f-righ + h3 + hr { margin-top: 24px !important; }
.MuiTablePagination-root:last-child { border-bottom: none !important;}
.btn-primary { color: #fff !important; background-color: $primary !important; width: 100%; margin-top: 10px !important; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); padding: 6px 16px; font-size: 1.075rem; }
.userbox { margin-top: 25px;}
.userbox .cstm-select { padding: 0 10px; }
.userbox img { margin: 0 auto; float: none; display: table; border-radius: 50%; }
.MuiSelect-icon { right: 7px !important; top: calc(50% - 9px) !important; }
.title-box-expire { padding: 0 14px; margin-bottom: -12px !important; float: left; width: 100%; }
.progress-status { width: 100%; height: 5px; background: #CCC; float: left; margin-top: 10px; margin-bottom: 15px; border-radius: 3px; overflow: hidden; position: relative; margin-bottom: 10px;}
.fillbox { position: absolute; height: 5px; }
.password-strength { float: right; font-weight: 600; font-size: 13px; margin-top: 9px; margin-bottom: -5px; }
.stepbox { margin-bottom: 25px !important; margin-top: 15px !important; }
.stepbox .fill.active { color: #2A323C; }
.stepbox .fill { width: 25px; height: 25px; float: left; background: #CCCCCC; border-radius: 50%; margin-top: -2px; margin-right: 13px; color: #000; text-align: center; font-weight: 600; line-height: 24px; }
.stepbox .active .fill { background: #DC9018; color: #fff; }
.stepbox .active { color: #DC9018; }
.stepbox .active .active-border { background: #DC9018; width: 35px; height: 3px; float: right; margin-top: 9px; }
.stepbox .active-border { background: #CCCCCC; width: 35px; height: 3px; float: right; margin-top: 9px; }
.stepbox .active.active-form .fill { border: 1px solid #DC9018; background: #fff; color: #DC9018; }
.stepbox .active.active-form .fill + .active-border { background: #CCCCCC; }
.strength-text { font-size: 15px; color: #808080; margin-top: 9px; float: left; width: 100%; }
.cstm-select { margin-top: 16px !important; margin-bottom: 8px !important;}
.link-btn { color: #fff !important; background-color: $primary !important; width: 100% !important; float: left; padding: 6px 16px; font-weight: 500; line-height: 1.75; border-radius: 4px; text-align: center; font-size: 1.075rem; margin-top: 10px !important; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); text-decoration: none; }
.link-btn-new { color: #fff !important; background-color: $primary !important; float: left; width: auto; padding: 6px 16px; font-weight: 500; line-height: 1.75; border-radius: 4px; text-align: center; font-size: 1.075rem; margin-top: 10px !important; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); text-decoration: none; }
.link-btn-default { padding: 6px 16px; font-weight: 500; line-height: 1.75; min-width: 64px; box-sizing: border-box; font-weight: 500; border-radius: 4px; float: left; margin-top: 14px !important; text-transform: uppercase; text-decoration: none; }
.bordertop { border-top: 2px solid $primary; padding: 15px;}
.error-title { margin-top: 10px !important; font-size: 22px !important; color: $error !important; }
.modal-header{ background: $error; }
.modal-title h2 { font-size: 17px !important; color: #fff; }
.btn-danger { background: $error !important; color: #fff !important;}
.card-title-box img { margin: 0 auto; display: table; max-height: 45px; }
.modal-footer-bottom { padding: 15px 24px 20px 23px; border-top: 1px solid #EFEFEF; }
.textarea-acount { border: 1px solid #C4C4C4; border-radius: 5px; background: transparent; padding: 18.5px 14px; font-family: 'Poppins', sans-serif; font-size: 13px; height: auto !important; }
.acount-number { font-size: 16px; margin-bottom: 8px; margin-top: 5px; }
.eur-main, .eur-checking { opacity: 0.4; float: left; width: 100%; }
.MuiSelect-select .acount-number { margin-bottom: 10px; }
.MuiSelect-select .eur-main, .MuiSelect-select .eur-checking { margin-top: 4px; }
.current_balance, .account_balance { font-size: 16px !important; margin-top: 0 !important; margin-bottom: 10px !important; } 
.account_balance-cstm { font-size: 16px !important; margin-top: 17px !important; margin-bottom: 0px !important; }
.account_balance-cstm-date { font-size: 16px !important; margin-top: 17px !important; margin-bottom: -14px !important;  }
.spacebox { padding: 15px;  border: 1px solid $tableBorderColorDark; float: left; width: 100%;  margin-top: 16px; border-radius: 5px; min-height: 114px;}
.current_price, .current_available { opacity: 0.4; margin: 0; }
.MuiFormControl-root.cstm-select.min-height .MuiOutlinedInput-notchedOutline { min-height: 120px; }
.bdr { border-bottom: 1px solid rgba(0,0,0,0.05); padding: 12px 0; font-size: 14px; }
.bdr_none { padding: 12px 0; font-size: 14px; }
.border0 { border: none !important;}
.text-primary { color: $primary; }
.valuetype { opacity: 0.6; }
.MuiOutlinedInput-notchedOutline { border: transparent;}
.news-date { opacity: 0.5; margin-top: 8px !important; margin-bottom: 5px !important; }
.MuiButton-text.news-link { background: #DC9018; color: #fff !important; text-transform: capitalize; padding: 4px 14px; font-size: 13px; margin-bottom: 19px !important; margin-left: 0px !important; }
.MuiButton-text.news-link:hover { background: #DC9018; }
.news-title { font-size: 21px !important; }
.profile-icon { float: left; font-size: 62px !important; margin-top: 0px; padding-bottom: 10px; border-bottom: 1px solid #D9D9D9; padding-right: 19px; }
.exchange-icon { float: left; font-size: 59px !important; margin-top: 2px; padding-bottom: 14px; border-bottom: 1px solid #D9D9D9; padding-right: 19px; }
.news { font-size: 15px; line-height: 24px; margin-bottom: 10px !important; }
.news-link { margin: 5px 0px 11px -7px !important; color: $primary !important;  } 
.responsive-img img { width: 100% !important;}
.list-type { float: left; width: 100%; padding: 15px 7px; font-size: 16px; }
.list-type a { padding: 10px 15px; text-decoration: none; margin-right: 10px; margin-bottom: 25px; font-weight: 400; border-radius: 7px; line-height: 1.643em; letter-spacing: 0em; text-transform: capitalize; font-size: 16px;}
.list-type a.active { box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.05); color: $primary; border-bottom: 2px solid $primary; text-decoration: none; cursor: pointer; }
.list-type .MuiButton-root:hover { color: $primary; background: transparent; }
.files_table .MuiIconButton-label { margin-left: 0px !important; }
.MuiTypography-root.Authenticator-title { font-size: 25px; font-weight: 600; margin-bottom: 6px; }
.debit-account { font-size: 18px !important; margin-top: 6px !important; margin-bottom: 9px !important; }
.border-top .MuiPaper-elevation1.MuiPaper-rounded { border-top: 2px solid $primary; }
.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.makeStyles-btnlink-61:hover { text-decoration: none; }
.debit-text { opacity: 0.4; }
.MuiGrid-root.list-type.float-left { float: left; width: auto; }
.MuiGrid-root.us-info { float: left; width: 100%; border-bottom: 1px solid rgba(211, 213, 218, 0.4); padding-bottom: 10px; }
.MuiGrid-root.us-info svg { font-size: 55px; float: left; }
.MuiGrid-root.main_user { float: left; padding-left: 13px; }
.MuiTypography-root.ad-name { font-size: 17px; font-weight: 500; }
.MuiTypography-root.ad-time { font-size: 12px; opacity: 0.7; font-weight: 500; }
.MuiTypography-root.message-text { margin-top: 0; font-size: 15px; float: left; width: 100%; padding: 15px; border-radius: 8px; }
.MuiGrid-root.left-side { float: left; width: 100%; margin-bottom: 25px; }
.MuiGrid-root.right-side .MuiGrid-root.us-info { float: right; text-align: right;  }
.MuiGrid-root.right-side .MuiGrid-root.us-info svg { float: right; color: $primary;}
.MuiGrid-root.right-side .MuiGrid-root.main_user { float: right; padding-right: 13px; padding-left: 0px; }
.MuiGrid-root.min-space { float: left; width: 100%; min-height: 360px; }
.MuiGrid-root.right-side .MuiGrid-root.us-info:last-child { border: none; }
.MuiTypography-root.title { font-size: 18px; margin-bottom: 10px; font-weight: 500; }
.link-header { color: #1C1303 !important;}
.whitebox-center { margin: 25px auto 0;}
.min-box { width: 100%; padding: 10px;}
.MuiGrid-root.progresscstm { position: fixed; background: #000; width: 100%; height: 100%; z-index: 111; }
.MuiGrid-root.progresscstm .MuiCircularProgress-root { position: absolute; margin: auto; left: 0; right: 0; top: 0; bottom: 0; width: 65px !important; height: 65px !important; }
.Verifybtn-cstm .MuiButtonBase-root { margin-top: 6px; margin-left: 15px; padding: 15px 20px; }
.Verifybtn-cstm .MuiButtonBase-root:hover, .Verifybtn-cstm .MuiButtonBase-root:focus { background: #DC9018;}

.makeStyles-main-17 > div { transform: none !important; opacity: 1 !important; }
.MuiGrid-root.rerer { transform: none !important; position: fixed; top: 0; z-index: 111111; }
.MuiAlert-action .MuiIconButton-label { margin-left: 0; }
button.MuiButton-root:hover { background-color: #DC9018; }
.MuiGrid-root.cstm-tabs .MuiAppBar-root { background: transparent; box-shadow: none; }
.MuiGrid-root.cstm-tabs .MuiBox-root { padding-left: 0; padding-right: 0; float: left; width: 100%; margin-bottom: 10px; }
.MuiGrid-root.cstm-tabs .MuiTab-root { min-width: auto; padding: 8px 35px; }
.MuiGrid-root.cstm-tabs .MuiTab-root.Mui-selected { background-color: $primaryDark; color: #fff; border-radius: 5px !important;}
.MuiGrid-root.cstm-tabs .MuiTabs-indicator { background: transparent; }
.pt-0-pb-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.space-remove { padding: 0 15px; }
.maxlength { margin: 40px auto 15px; display: table; width: 90% !important; }
.maxlength .styles_react-code-input__CRulA > input { margin: 0 4px; border: 1px solid #C4C4C4 !important; border-radius: 4px !important; width: calc(15% - 2px) !important; height: 58px !important; font-weight: 600; }
.lastchild-border-remove .MuiGrid-root:last-child hr { display: none; }
.button-space-height a { width: 100%; min-height: 98px; }
.MuiSelect-outlined.MuiSelect-outlined .eur-checking { display: none; }

.MuiCard-root {
	&.bg-primary {
		background-color: $primary;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $primary;
			color: #fff;
		}
	}
	&.bg-secondary {
		background-color: $secondary;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $secondary;
			color: #fff;
		}
	}
	&.bg-error {
		background-color: $error;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $error;
			color: #fff;
		}
	}
	&.bg-warning {
		background-color: $warning;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $warning;
			color: #fff;
		}
	}
	&.bg-info {
		background-color: $info;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $info;
			color: #fff;
		}
	}
	&.bg-success {
		background-color: $success;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $success;
			color: #fff;
		}
	}
	&.bg-dark {
		background-color: $dark;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $dark;
			color: #fff;
		}
	}
	&.outline-primary {
		border: 1px solid + $primary;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $primary;
		}
	}
	&.outline-secondary {
		border: 1px solid $secondary;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $secondary;
		}
	}
	&.outline-error {
		border: 1px solid $error;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $error;
		}
	}
	&.outline-warning {
		border: 1px solid $warning;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $warning;
		}
	}
	&.outline-info {
		border: 1px solid $info;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $info;
		}
	}
	&.outline-success {
		border: 1px solid $success;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $success;
		}
	}
	&.outline-dark {
		border: 1px solid $dark;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $dark;
		}
	}
}
.btn-submit { max-width: 530px; margin: 20px auto 20px !important; padding: 10px 25px !important; }
.MuiFormLabel-root.Mui-disabled { color: #A9A9A9 !important }
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline { border-color: #c6c6c6 !important ;}
.MuiButton-text.tr-bg:hover, .MuiButton-text.tr-bg:focus { background: transparent; text-decoration: underline; }
.p-20 { padding: 20px; }
.MuiButton-root.link-btn { padding: 6px 16px; font-size: 1.075rem; min-width: 64px; box-sizing: border-box; font-weight: 500; line-height: 1.75; border-radius: 4px; text-transform: uppercase; text-decoration: none; }
.chechbox-size{ margin-left: -11px !important; margin-top: 10px !important; }
.view-btm-card { float: left; width: 100%; padding: 15px 0; }
.tablink { float: left; width: 100%; border-bottom: 1px solid rgba(0, 0, 0, 0.12); margin-bottom: 17px;  }
.tab-position { position: relative; z-index: 11;}
.tablink a { padding: 15px 15px; float: left; text-decoration: none; font-weight: 200; border-radius: 0; text-transform: capitalize;}
.link-default { text-decoration: none; margin-top: 10px; float: left;}
.text-mute { color: $secondaryLight;}
.tablink a.active { border-bottom: 2px solid $primary; color: $primary; }
.view-btm-card{ border-bottom: 1px solid rgba(0, 0, 0, 0.12);}
.MuiTableCell-root { padding-top: 15px !important; padding-bottom: 15px !important; border-bottom: 1px solid #f2f2f2 !important; }
.MuiIconButton-label { margin-left: 15px; }
.full-width { float: left; width: 100%; margin-top: 25px !important; margin-bottom: 15px !important;}
.MuiSwitch-thumb { margin-left: -14px !important; }
.MuiSwitch-track { background-color: #756d6d !important; }
.card-title { font-size: 15px; opacity: 0.5; margin-bottom: 3px; text-transform: uppercase; }  
.select-box { margin-top: 16px !important; margin-bottom: 8px !important; }
.btn.btn-primary.m-0 { margin: 0 !important; }
.pull-right { float: right; }
.pull-left { float: left; }
.mt-5 { margin-top: 50px !important;}
.mt-4 { margin-top: 40px !important;}
.mt-3 { margin-top: 30px !important;}
.mt-2 { margin-top: 20px !important;}
.mt-1 { margin-bottom: 10px !important;}
.mb-5 { margin-bottom: 50px !important;}
.mb-4 { margin-bottom: 40px !important;}
.mb-3 { margin-bottom: 30px !important;}
.mb-2 { margin-bottom: 20px !important;}
.mb-1 { margin-bottom: 10px !important;}
.ml-3 { margin-left: 30px !important;}
.pt-0 { padding-top: 0px !important; }
.pb-0 { padding-bottom: 0px !important; }
hr.MuiDivider-root { background: $divider !important;}

.m-0.setcard { float: left; width: 100%; margin-top: 25px !important; margin-bottom: -10px !important; }
.f-right, .right-button.f-righ { float: right; }
.p-0 { padding: 0 !important; }
.m-0 { margin: 0 !important; }
.p-20 { padding: 20px !important; }

.responsive-table-card {
	.MuiTableCell-root {
		white-space: unset;
	}
}



/* Home Page */
.MuiGrid-root.menu-link link { border-bottom: 2px solid transparent; border-radius: 0; font-size: 15px; padding: 6px 0; font-weight: 600; margin: 0 10px; }
img.map-img { width: 100%; margin-top: 50px; }
.MuiGrid-root.menu-link link:hover, .MuiGrid-root.menu-link link:focus { color: #DC9018;}
.MuiGrid-root.pull-right.top-space { margin-top: 23px; position: absolute; right: 20px; }
.MuiTypography-root.slider-main-title.text-uppercase { text-transform: uppercase; margin-bottom: 25px; }
.MuiTypography-root.slider-main-title { font-size: 49px; line-height: 65px; font-weight: 800; }
.MuiTypography-root.slider-inner-title { font-size: 17px; max-width: 380px; color: #487B90; }
.get-started-btn { background: #3C1360; color: #fff; text-decoration: none; padding: 13px 35px; float: left; margin-top: 24px; font-size: 18px; border-radius: 61px; }

.MuiTypography-root.main-title-full { text-align: center; font-size: 42px; line-height: 65px; font-weight: 700; }
.get-started-btn.center-btn { margin: 25px auto 0; display: table; float: none; }
.main-title-full.text-left.ab-title { font-size: 42px; text-align: left; font-weight: 700;  margin-bottom: 40px; }

.MuiGrid-root.Personal-account-box { border-radius: 10px; background: #fff; float: left; width: 100%; box-shadow: 0px 5px 15px #d2d2d2; overflow: hidden; margin-top: 35px; }
.MuiGrid-root.Personal-account-box .MuiTypography-root.plan-title { text-align: center; background: #DC9018; padding: 4px 5px; font-weight: 500; font-size: 30px; color: #fff; }
.MuiGrid-root.logo-item img { margin: 28px auto 15px; display: table; padding-bottom: 20px; }
.MuiTypography-root.plan-type { text-align: center; font-weight: 600; font-size: 19px; color: #22201E; margin-bottom: 0; }
p.MuiTypography-root.price-small-text { color: #242c3a !important; margin: -19px auto 15px; display: table; border-bottom: 1px solid #DBDBDB; font-weight: 600; padding-bottom: 2px; text-transform: uppercase; }
.MuiGrid-root.Personal-account-box .get-started-btn { margin: 25px auto 16px; display: table; float: none; font-size: 15px; padding: 8px 35px; font-weight: 600; }
.MuiGrid-root.Personal-account-box.Advanced .MuiTypography-root.plan-title { background: #336600;}

.MuiGrid-root.Personal-account-box.Pro .MuiTypography-root.plan-title { background: #0C4B72;}
.user-shadow img {box-shadow: 7px 7px 9px 2px rgb(189 189 189 / 75%); }

.MuiGrid-root.home-tabs.innerhome { max-width: 650px; background-image: linear-gradient(to right, #725792, #644c80, #56426f, #48385e, #3b2e4d); }
.MuiGrid-root.home-tabs.innerhome .BitcoinmatBox { text-transform: uppercase; border-radius: 10px; background-image: linear-gradient(to left, #715590, #725692, #745793, #755995, #775a97); max-width: 480px; font-size: 32px; font-weight: 500; text-align: center; color: #fff; margin: 0 auto; width: 100%; }
p.MuiTypography-root.declare-text { color: #fff; margin-top: 11px; }
.textBox-option .MuiOutlinedInput-root { border-radius: 50px; background: #fff; text-align: center; }
.textBox-option .MuiOutlinedInput-input {  padding: 10px 14px; text-align: center; font-size: 16px; font-weight: 600; }

.MuiGrid-root.textBox-option.textBox-option-inner { max-width: 230px; position: relative; margin: 0 auto; }
.MuiGrid-root.textBox-option.textBox-option-inner .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth + span { position: absolute; right: 12px; background: #E3EAF0; border-radius: 0 35px 35px 5px; font-size: 23px; float: left; line-height: 43px; padding-left: 15px; color: #000; padding-right: 16px; }

.textBox-option .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline { border: none; }
.MuiGrid-root.textBox-option.textBox-option-inner input { padding-right: 80px; }
.MuiGrid-root.minBox { float: left; width: 100%; min-height: 170px; }

.MuiGrid-root.btn-box { margin: 0 auto; display: table; float: none; }
.MuiGrid-root.btn-box .MuiButton-root.get-started-btn { width: auto; padding: 12px 35px; margin: 0 5px; }
.MuiGrid-root.btn-box .MuiButton-root.get-started-btn.back-default { background: #6E6E6E; }
.MuiGrid-root.btn-box .MuiButton-root.get-started-btn.continue-default { background: #725792; }
.get-started-btn.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important;
}
.MuiGrid-root.btn-box .MuiButton-root.get-started-btn.back-default:hover, .MuiGrid-root.btn-box .MuiButton-root.get-started-btn.back-default:focus { background: #a7a7a7; }
.MuiGrid-root.btn-box .MuiButton-root.get-started-btn.continue-default:hover, .MuiGrid-root.btn-box .MuiButton-root.get-started-btn.continue-default:focus { background: #3D3050; }

.center-mobile { width: 100%; max-width: 270px; margin: 0 auto; display: table;}
.center-mobile-map { width: 100%; margin: 0 auto; display: table; margin-top: 25px; max-width: 820px; }
.center-mobile-big { width: 100%; max-width: 450px; margin: 0 auto; display: table;}
.align-center { align-items: center !important; display: flex; }
.MuiTypography-root.main-title-full-inner { font-size: 19px; margin-top: 16px; font-weight: 700; }

.img-box { width: 70px; float: left; }
.img-box .center-mobile { max-width: 58px; float: left; margin-right: 15px; margin-top: 6px; }
.MuiGrid-root.img-content-box { width: calc(100% - 70px); float: left; }
.MuiTypography-root.solution-title { font-size: 27px; font-weight: 600; }
.MuiTypography-root.solution-des {  font-size: 17px; opacity: 0.5; font-weight: 500; }

.MuiGrid-root.title-spance { margin-top: 50px; }
.MuiGrid-root.about-box { padding: 25px; border-radius: 15px; margin: 25px 0; box-shadow: 7px 7px 9px 2px rgb(189 189 189 / 75%); margin-left: 5px; background: #fff; }
.MuiFormControl-root.position-select { width: 150px; float: left; }
.transaction_check.MuiFormControl-fullWidth { width: calc(100% - 346px); float: left; margin-left: 20px; }
.MuiGrid-root.about-title .MuiTypography-root { text-align: center; font-size: 23px; font-weight: 600; margin-bottom: 14px; }
.MuiGrid-root.about-img img { width: 100%; max-width: 100%; }
.MuiGrid-root.about-des .MuiTypography-root { text-align: center; font-size: 17px; margin-top: 21px; opacity: 0.8; font-weight: 500; }

.MuiGrid-root.user-img img { width: 100%; max-width: 190px; margin: 0 auto; border-radius: 50%; float: none; display: table; }
.MuiTypography-root.main-user-name { text-align: center; font-size: 20px; font-weight: 600; margin-top: 19px; }
.MuiTypography-root.main-user-deg { text-align: center; font-size: 16px; }
.MuiGrid-root.company-user-details p { font-size: 16px; margin-bottom: 19px; line-height: 27px; font-weight: 500; }
.MuiGrid-root.btm10 { margin-bottom: 35px; }
.compant-logo { width: 100%; max-width: 200px; margin: 0 auto; display: table; }
.MuiGrid-root.partner-space { padding: 20px 0; }
.MuiButton-text.more:hover { background: $primary; }
.staticbg { background: #fff !important;}
.MuiTextField-root.staticbgnew input { background: #fff; color: #000; }
.MuiGrid-root.footer { padding: 30px 0 30px; float: left; width: 100%; background-image: linear-gradient(to right, #725792, #644c80, #56426f, #48385e, #3b2e4d); }
.MuiTypography-root.footer-title { font-size: 18px; font-weight: 700; margin-bottom: 10px; }
.MuiGrid-root.link-footer a { color: #fff; float: left; width: auto; font-weight: 400; text-decoration: none; font-size: 16px; display: block; text-align: left; text-transform: capitalize; border-right: 1px solid #fff; border-radius: 0; padding: 0 10px !important; line-height: 15px; margin-bottom: 10px; }
.MuiGrid-root.link-footer a:hover { background: transparent;}
img.nextarrow { max-width: 27px; float: left; margin-left: 9px; margin-top: -6px; }
.social-media-footer img { max-width: 30px; margin-right: 15px; }
.MuiGrid-root.social-media-footer {  width: auto; margin-top: 10px; float: left;}

.MuiGrid-root.link-footer { float: left; margin: 20px auto 0; display: table; margin-left: -10px; }
.MuiGrid-root.Transaction-box { text-align: right; }
.MuiGrid-root.Transaction-box p { font-size: 15px; font-weight: 400; color: #fff; margin: 10px 0; }
.MuiGrid-root.Transaction-box p span { font-weight: 600;}
.MuiGrid-root.Transaction-box1 {  color: #fff; padding: 10px 45px; }

.MuiGrid-root.border-lit { height: 110px; width: 1px; background: #fff; position: relative; margin-top: 5px; float: right; }

.drp-eur .MuiFormControl-root.dropmenu-home, .drp-eur-black .MuiFormControl-root.dropmenu-home { float: right; margin-top: 20px; margin-bottom: -20px; }
.drp-eur .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .drp-eur-black .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline { border: none;}
.drp-eur .MuiOutlinedInput-root .MuiSelect-icon { color: #000;}
.drp-eur select.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input { font-weight: 600; font-size: 16px; border-bottom: 2px solid #000 !important; border-radius: 0;  }
.drp-eur-black select.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input { border-bottom: 2px solid #fff; color: #fff; font-weight: 600; font-size: 16px; border-radius: 0; }
 
img.plan-bg { margin: 0 auto; display: table; float: none; }
.drp-eur-black .MuiOutlinedInput-root .MuiSelect-icon { color: #fff;}
.align-center.dark1 .MuiGrid-root.about-box { box-shadow: none;  background: #000000; }
.align-center.dark1 .MuiGrid-root.about-box .MuiGrid-root.about-des .MuiTypography-root	 { color: #6E6E6E; opacity: 1;}

.arrow-select-default { position: absolute; right: 0; top: 16px; }
.MuiGrid-root.social-media-footer .MuiButtonBase-root.MuiButton-root.MuiButton-text { float: left; width: auto; max-width: none !important; padding: 0px; }
.MuiTypography-root.copyright { font-weight: 600; font-size: 15px; }
.MuiButton-text.btn-user { border: 1px solid #2B2B2B; margin-right: 10px; padding: 1px 10px; }
.MuiButton-text.btn-user.active-btn, .MuiButton-text.btn-user:hover {  background: #DC9018; color: #fff; border-color: #DC9018; } 
.header-space { padding: 5px 0;}

.MuiGrid-root.light-mode, .MuiGrid-root.dark-mode { float: right; margin-top: 2px; }
.MuiGrid-root.light-mode .MuiButtonBase-root, .MuiGrid-root.dark-mode .MuiButtonBase-root { padding: 0; background: transparent; min-width: auto; }
.MuiGrid-root.dark-mode .MuiSvgIcon-root { color: #fff; }

/* Black Tab */
.home-tabs.home-tabs-black .new-main-tab .MuiNativeSelect-select.MuiNativeSelect-select { color: #fff; }
.home-tabs.home-tabs-black .box-size input { background: rgb(15,15,15); background: linear-gradient(9deg, rgba(15,15,15,1) 0%, rgba(32,32,30,1) 100%); color: #fff !important; }
.home-tabs.home-tabs-black .MuiGrid-root.firstbox { border-color: #121213;}
.home-tabs.home-tabs-black .box-select { background: rgb(15,15,15); background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(32,32,30,1) 100%); }
.home-tabs.home-tabs-black .new-main-tab { box-shadow: 0px 7px 40px 0 rgb(0 0 0 / 35%); border: 1px solid #000;}
.MuiGrid-root.home-tabs svg.MuiSvgIcon-root.arrow-select-default { color: #C4C4C4; }
.price-box-shadow-none .MuiGrid-root.Personal-account-box { box-shadow: none; }


.flag { max-width: 36px; }
.MuiButton-text.language, .MuiButton-text.language:hover { padding: 0px; background: transparent; margin-top: 2px; }
.MuiGrid-root.menu-link link.active { border-bottom: 2px solid #DC9018; border-radius: 0; color: $primary; }
.MuiGrid-root.menu-link a.active { border-bottom: 2px solid #DC9018; border-radius: 0; color: $primary; }
.MuiGrid-root.menu-link link:hover { background: transparent; border-bottom: 2px solid #DC9018; border-radius: 0; }
.flag-img .flag { margin: 0 14px;  border: 1px solid #A0A0A0; }

.MuiSnackbar-root.fixbar-message { position: fixed; top: 80px; z-index: 111; }
img.country { float: left; width: 26px; margin-right: 12px; margin-top: -2px; }

img.center-mobile-big.small-size-online { max-width: 280px; }
img.center-mobile-big.small-size-online2 { max-width: 220px; }
img.center-mobile-big.small-size-online1 { max-width: 310px; }
.center-mobile.small-size-online2 { max-width: 400px; }
img.center-mobile-big.center-mobile-bighand { margin-bottom: -100px; max-width: 530px; }
.width90 { width: 90%; max-width: 100%; }
.youtube {  max-width: 50px !important; width: 50px !important; }

button.control-arrow.control-next, button.control-arrow.control-prev, p.carousel-status, .thumbs-wrapper.axis-vertical { display: none; }
.carousel.carousel-slider { padding-bottom: 40px; }

.carousel .control-dots .dot { background: #6E6E6E !important; width: 12px !important; height: 12px !important; box-shadow: none !important; margin: 0 5px !important; }
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover { background-color: #3C135F !important;}

.MuiGrid-root.light-mode-new input.MuiInputBase-input.MuiOutlinedInput-input { background: #EAEAEA !important; }
.MuiGrid-root.dark-mode-new input.MuiInputBase-input.MuiOutlinedInput-input { background: #ECECEC !important; }
.center-mobile.min-height { min-height: 210px; }
p.MuiTypography-root.small-text-btm { font-size: 11px; line-height: 16px; color: #fff; margin-top: 0px; border-top: 1px solid #BEBEC0; padding-top: 16px; text-align: center; margin-bottom: 24px; }
.MuiGrid-root.minBox.center .MuiGrid-root { margin: 15px auto; }

/* Responsive Menu */
.MuiGrid-root.logo-box { max-width: 400px; width: 100%; margin: 0 auto; }
.MuiGrid-root.menu-space.MuiGrid-item { float: left; width: calc(100% - 475px); }
.MuiGrid-root.left-menu.MuiGrid-item { float: left; width: 205px; }
.MuiGrid-root.right-menu.MuiGrid-item { float: right; width: calc(100% - 205px) ; }
.MuiGrid-root.menu-link { margin: 12px auto 0; display: table; }
.MuiGrid-root.login-box.MuiGrid-item { order: 2; }

.submenu .MuiMenu-paper { width: 100%; left: 0 !important; max-width: 100%; margin-top: 53px; border-radius: 0; }
.submenu .MuiMenu-paper .MuiMenu-list { max-width: 1250px; margin: 0 auto; display: table; width: 100%; padding: 15px 0; }

.MuiGrid-root.menu-img { width: 65px; float: left; }
.MuiGrid-root.menu-text { width: calc(100% - 65px);  float: left; margin-top: 5px; }
.MuiTypography-root.menu-text-main { font-size: 17px; font-weight: 500; line-height: 29px; }
.MuiTypography-root.menu-text-sub { font-size: 12px; opacity: 0.6; }
.MuiGrid-root.main-menu-box { padding: 8px; }

.MuiTypography-root.main-title { text-align: center; font-size: 45px; font-weight: 600; margin-top: -10px; margin-bottom: 25px; padding: 0 15px; }
.MuiTypography-root.terms-main-title { text-align: center; font-weight: 600; max-width: 720px; margin: 5px auto 20px; font-size: 16px; }
.MuiTypography-root.terms-main-des { font-weight: 500; line-height: 27px; margin-bottom: 25px; }
.order-2 { order: 2;}

a.MuiButtonBase-root.MuiButton-root.MuiButton-text.linkredirect { background: transparent; padding: 0; margin-top: 15px; font-weight: 700; color: $primary; font-size: 15px; }
.MuiTypography-root.infolink { font-size: 17px; font-weight: 500; }

.table-title .MuiTab-root { font-size: 28px; font-weight: 600;  border: none; border-radius: 15px; text-transform: capitalize; opacity: 1; padding: 0 20px; }
.table-title .Mui-selected { background: $primary; color: #fff; }
.MuiTabs-root.table-title .MuiTabs-flexContainer + span { display: none; }
.MuiTypography-root.table-title span { background: $primary; color: #fff;  padding: 5px 12px; margin-left: 16px; border-radius: 8px;}
.img-center-check { max-width: 23px; margin: 0 auto; display: table; }
.space-pad th, .space-pad td { padding: 0 !important; }
p.MuiTypography-root.table-title { font-size: 28px; font-weight: 600; margin-bottom: 25px; }
.opacity-none { opacity: 0; }
.logo-box img { max-width: 376px; width: 100%; }

.react-swipeable-view-container { display: block !important; }
.MuiTypography-root.main-title.text-left { text-align: left; padding-left: 0; margin-bottom: 0; }
.MuiTypography-root.main-title-sub.text-left { font-size: 22px; font-weight: 600; max-width: 520px; margin: 20px 0px 30px 0; width: 100%; }
.MuiTypography-root.main-title-box { text-align: center; font-weight: 600; font-size: 24px; text-transform: uppercase; margin-bottom: 24px; }
.fullwidthnew { float: left; width: 100%; }
.cstm-pad { padding: 100px 20px !important; }
.MuiTypography-root.operator { max-width: 760px; text-align: center; margin: 30px auto 30px; font-weight: 600; font-size: 23px; }

.help_center_link { float: left; width: 100%; }
.help_center_link p { float: left; width: 100%; }
.help_center_link p a { text-decoration: none; }
.MuiTypography-root.address { font-size: 23px; line-height: 39px; margin-top: 15px; }
.MuiButton-text.get-direction { font-size: 22px; margin-left: -8px !important; margin-top: 20px; }
.MuiTypography-root.main-title-sub { font-size: 18px; text-align: center; max-width: 420px; margin: 0 auto; display: table; font-weight: 500; }
.MuiTypography-root.main-title.main-title-sub-main { margin-bottom: 10px; }
.MuiTypography-root.main-title-sub.full { max-width: 100%; margin-bottom: 25px; }
.MuiTypography-root.slider-main-title.text-center.download-text { margin-top: 35px; text-align: center; font-size: 35px; }
.txt-center { text-align: center !important;}
.MuiGrid-root.storebox { margin: 0 auto; display: table; }
.MuiGrid-root.storebox img { max-width: 275px; margin: 15px 7px 0; }
.MuiTypography-root.question { margin-top: 15px; font-size: 25px; font-weight: 600; }
iframe.iframe-top { margin-top: 35px; margin-bottom: 25px; }

.MuiGrid-root.product { padding-top: 25px; background: #fff; padding: 15px; float: left; width: 100%; }
.MuiGrid-root.product-pic { border: 4px solid #F2F2F2; }
.MuiGrid-root.product-pic img { max-width: 100%; }
.MuiTypography-root.product-name { font-size: 17px; margin-top: 14px; font-weight: 500; color: #797C80; }
.MuiTypography-root.product-price { font-size: 17px; margin-top: 1px; font-weight: 500; color: #797C80; }
.MuiButton-text.load-more1 { padding: 14px 50px; background: #3C1360; color: #fff; border-radius: 4px; text-transform: uppercase; margin-left: 20px; }
.MuiButton-text.load-more { display: table; padding: 12px 50px; background: #3C1360; color: #fff; margin: 35px auto 20px; border-radius: 61px; text-transform: uppercase; }
.MuiButton-text.load-more:hover { background: #DC9018; }
.MuiTableContainer-root.simple-table tr { background: transparent; border-bottom: 2px solid #747474; }
.MuiTableContainer-root.simple-table .MuiTableCell-head { font-size: 18px; }
.MuiTableContainer-root.simple-table thead.MuiTableHead-root { background: transparent; }
.MuiTableContainer-root.simple-table td { font-size: 18px; }
.MuiTableContainer-root.simple-table img { max-width: 50px; float: left; padding-right: 10px; margin-top: -6px; }
.position-relative { position: relative; }
img.qr-position { max-width: 40px; position: absolute; right: 6px; margin-top: 7px; }
td.MuiTableCell-root.plus { color: #7C9548; }
td.MuiTableCell-root.minus { color: #B91818; }
.MuiTableContainer-root.simple-table { float: left; width: 100%; margin-top: 45px; }
td.MuiTableCell-root.text-right { width: 185px; }
button.btn-buy { color: #fff; }
button.MuiButton-text.btn-sell { background: #E96F16; color: #fff; margin-left: 5px; }
.MuiGrid-root.menu-img img { float: left; width: 100%; }
.MuiGrid-root.flwidth:hover, a.MuiButton-text.flwidth:hover { background: rgba(0,0,0,0.04); }
p.MuiTypography-root.main-title-sub.text-left.main-left { text-align: left; }
p.MuiTypography-root.main-title-sub.text-left.main-left.newcenter { margin: 20px auto 30px; text-align: center; }
.menu-link a.MuiButtonBase-root.MuiButton-text { font-weight: 600; border-bottom: 2px solid transparent; border-radius: 0; padding-left: 0px; padding-right: 0px; margin-left: 10px; }
.menu-link a.MuiButtonBase-root.MuiButton-text:hover { border-color: $primary; background: transparent; color: $primary; }
.MuiGrid-root.flwidth:hover, a.MuiButton-text.flwidth.active .MuiTypography-root.menu-text-main { color: $primary;}
.text-center-new { text-align: center !important;}
.social-media-footer.newMedia a img { width: 20px; margin-top: 10px; }


/* CSTM TAB */
.MuiGrid-root.tabfullWidth { width: 100%; padding: 15px 25px; max-width: 580px; margin: 0 auto; display: table; float: none; }
.new-main-tab {z-index: 5; width: 100%; float: left; position: relative; }
.MuiGrid-root.money-select { float: left; position: relative; }
.MuiGrid-root.box-size { float: left; width: calc(100% - 82px); }
.box-size input { background: #FFFFFF; color: #000; font-size: 18px; font-weight: 400; min-height: 42px; width: 100%; padding-left: 15px; text-align: right; padding-right: 20px; }
.MuiGrid-root.box-size .MuiInputBase-root.MuiInput-root.MuiInput-underline { width: 100%; border: none; }
.box-size input:hover, .box-size input:focus { border: none !important; }
.MuiGrid-root.box-size.fullWidth.copyclipboardbox { position: relative; }
.MuiGrid-root.firstbox { float: left; width: 100%; /* border: 1px solid #ebedfb; */ }

.MuiGrid-root.new-main-tab.sub .MuiGrid-root.firstbox .MuiGrid-root.box-size { width: 100% }
.MuiGrid-root.new-main-tab.sub .MuiGrid-root.firstbox .MuiGrid-root.box-size .font-bold-textbox input { text-align: center; padding-left: 40px; padding-right: 40px; }
img.drparrow { position: absolute; z-index: 1; right: 3px; max-width: 24px; margin-top: 14px; pointer-events: none; }
.MuiGrid-root.new-main-tab.sub { box-shadow: none; z-index: 1; margin-top: -1px; }
.new-main-tab .MuiNativeSelect-select.MuiNativeSelect-select { color: #444; font-weight: 600; padding-left: 5px; min-height: 35px; padding: 10px 0 10px 13px; }

.MuiGrid-root.box-size.fullWidth { width: 100%; }
.MuiGrid-root.box-size.fullWidth input { min-height: 35px; font-size: 16px; color: #000; font-weight: 600; padding-left: 20px; text-align: center; }
p.MuiTypography-root.email-typo { color: #444; font-weight: 600; margin-top: 13px; padding-left: 14px; font-size: 14px; }
p.MuiTypography-root.hover-text { display: none; position: absolute; }
.font-bold-textbox input { font-size: 16px; color: #000; font-weight: 600; }

.shadowboxlight .help_center_link p a, .shadowboxlight .help_center_link p button { background: transparent; box-shadow: 3px 5px 8px #d7d4d4; }
.shadowboxlight .help_center_link p a:hover, .shadowboxlight .help_center_link p button:hover { background: $primary; color: #fff; }

.help_center_link p a:hover, .help_center_link p button:hover { background: $primary; color: #fff; }

svg.MuiSvgIcon-root.help { color: #CCCCCC; position: absolute; right: 13px; width: 19px; margin-top: 12px; cursor: pointer; }
.new-main-tab .MuiInput-underline:hover:not(.Mui-disabled):before, .new-main-tab .MuiInput-underline:after, .new-main-tab .MuiInput-underline:before { border: none !important;}
.box-select { background: #eff1f6; position: relative; height: 100%; cursor: pointer; min-height: 53px; padding-left: 15px; width: 68px; border: none; color: #444; font-weight: 600; }
.box-select:focus-visible { box-shadow: none; outline: none;}
ul.main-tab { padding: 0; border-bottom: 1px solid #D5D5D5; padding-bottom: 15px; margin: 0 auto; display: table; float: none; }
.main-tab + .react-tabs__tab-panel { float: left; width: 100%; }
ul.main-tab li { pointer-events: none; font-weight: 600; cursor: pointer; list-style: none; float: left; font-size: 15px; margin: 0; padding:  8px 11px; border-radius: 5px; text-align: center; }
.MuiGrid-root.home-tabs { background: rgb(114,87,146); background: linear-gradient(90deg, rgba(114,87,146,1) 0%, rgba(59,46,76,1) 100%); position: relative; max-width: 700px; margin: 0 auto; border-radius: 10px; box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.0), 0px 4px 5px 0px rgba(0, 0, 0, 0.0), 0px 1px 10px 0px rgba(0, 0, 0, 0.1); padding: 5px; }
.main-tab .react-tabs__tab--selected { background: $primary; color: #fff; }
p.MuiTypography-root.card-title.MuiTypography-body1 { float: left; width: 100%; opacity: 1; font-size: 32px; font-weight: 500; text-align: center; border-bottom: 1px solid #BEBEC0; padding-bottom: 10px; }
.MuiGrid-root.inner-tab { float: left; width: 100%; margin-top: 5px; margin-bottom: 10px; }

.MuiGrid-root.halfwidth { width: 43%; float: left; padding: 0 10px; margin: 7px 0 2px 0; font-size: 27px; text-align: left; line-height: 5px; }
.MuiGrid-root.halfwidth.right-border { text-align: right; }
.MuiGrid-root.halfwidth a { font-size: 16px; font-weight: 500; color: #949494; text-decoration: none; line-height: 15px; }
.MuiGrid-root.halfwidth.right-border { border-right: 1px solid #b8b9c3; }
.MuiGrid-root.agreeCheckbox label.MuiFormControlLabel-root, .MuiGrid-root.agreeCheckbox span.MuiIconButton-label { margin-right: 0; margin-left: 0px; }

.MuiGrid-root.fullWidth { float: left; width: 100%; margin-top: 15px; }
.MuiGrid-root.agreeCheckbox { margin: 0 auto; display: table; color: #fff; }
.MuiGrid-root.link-footer.text-right a { text-align: right !important; }

.MuiGrid-root.inner-tab ul.react-tabs__tab-list { margin: 0 auto 15px; display: table; float: none; padding: 0; }
.MuiGrid-root.inner-tab ul.react-tabs__tab-list li { opacity: 0.8; font-weight: 700; cursor: pointer; list-style: none; float: left; font-size: 16px; margin: 0 5px; padding: 8px 15px; border-radius: 5px; text-align: center;}
.MuiGrid-root.inner-tab ul.react-tabs__tab-list li.react-tabs__tab--selected  { opacity: 1; border-bottom: 3px solid  $primary; border-radius: 0px;}
.MuiGrid-root.inner-tab ul.react-tabs__tab-list li:focus-visible, ul.main-tab li:focus-visible { outline: none; }
.MuiFormControl-root.dropmenu-home { width: 95px; }
.dropmbox-home.MuiFormControl-fullWidth { width: calc(100% - 98px); float: right; }
.MuiGrid-root.tab-info { float: left; width: 100%; margin: 2px 0; }
.MuiFormControl-root.dropmenu-full { width: 100%; }
p.MuiTypography-root.wallet-link { margin-top: 5px; float: left; width: 100%; text-align: center; }
.MuiButton-root.get-started-btn { min-width: 150px; background: #735792; color: #fff; padding: 12px 15px; font-size: 18px; font-weight: 500; margin-top: 11px; }
.MuiButton-root.get-started-btn.search-default { width: 100%; background: #298D44; color: #fff; padding: 12px 15px; font-size: 18px; font-weight: 500; margin-top: 11px; }

.MuiButton-root.get-started-btn:hover, .MuiButton-root.get-started-btn:focus { background: #3D3050;}
.radius-small { border-radius: 8px !important;}
.background-box { background-image: linear-gradient(to bottom, #e9eef2, #eef1f5, #f4f5f7, #f8f8fa, #fcfcfc); border-radius: 15px; }

.MuiGrid-root.map-styles { min-height: 500px !important; width: 100% !important; }
.MuiGrid-root.map-styles-home { padding-top: 50px; min-height: 500px !important; width: 100% !important; }

header.MuiPaper-root.default-tabs { background: transparent; box-shadow: none; }
header.MuiPaper-root.default-tabs a.MuiButtonBase-root { text-transform: capitalize; padding: 0; padding-left: 0; min-width: 90px; margin-right: 25px; font-size: 18px;}
header.MuiPaper-root.default-tabs.dark a.MuiButtonBase-root { color: #fff; }
header.MuiPaper-root.default-tabs .MuiTabs-indicator { background-color: $primary; }
.table-rounded .MuiPaper-rounded { overflow: hidden; }
.MuiGrid-root.highlight-box .MuiTypography-root.Staking { background: $primary; width: 100%; margin: 0 auto; display: table; max-width: 470px; text-align: center; font-size: 23px; padding: 16px 0; border-radius: 5px; font-weight: 500; color: #fff; }
.MuiGrid-root.highlight-box .MuiTypography-root.Staking span { font-weight: 600; }
.MuiGrid-root.highlight-box.color-blue .MuiTypography-root.Staking { background: #723789; }
.MuiGrid-root.highlight-box { margin: 10px 0; }

.money-select .MuiInputBase-root.MuiInputBase-fullWidth { background: #EFF1F6; overflow: hidden; border: none; min-height: 55px; width: 82px; border-radius: 0px; }
.money-select .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline { border: none; }

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list.MuiList-padding { padding: 0; }
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list.MuiList-padding li.MuiButtonBase-root { margin: 0; }

.MuiFormControl-root.cstm-line-select .MuiInput-underline:before, .MuiFormControl-root.cstm-line-select .MuiInput-underline:after { border: none !important;}
.MuiFormControl-root.cstm-line-select .MuiSelect-outlined.MuiSelect-outlined { padding-top: 18px; padding-bottom: 18px; padding-left: 18px; }
li.MuiButtonBase-root.MuiMenuItem-root.Mui-selected.Mui-selected { background: rgba(0, 0, 0, 0.1); border-radius: 4px !important; margin-bottom: 2px; margin-left: 4px; margin-top: 2px; }

.MuiDialog-root.dialogs-msg-popup .MuiDialog-paperWidthSm { width: 100% !important; } 
.MuiDialog-root.dialogs-msg-popup .danger { color: #f50808; font-size: 19px; text-align: center; margin-top: 20px; font-weight: 500; }
.MuiDialog-root.dialogs-msg-popup .danger-blue { color: #0000FF; font-size: 19px; text-align: center; margin-top: 20px; font-weight: 500; }
.MuiDialog-root.dialogs-msg-popup .success { color: #2d8d21; font-size: 19px; text-align: center; margin-top: 20px; font-weight: 500; }
.MuiDialogActions-root.cancel-btn .MuiButton-text { background: #c5c5c5; color: #464646; margin: 0 auto 25px; padding: 6px 28px; }

.MuiDialog-root.dialogs-msg-popup .MuiDialog-paperWidthSm { max-width: 430px;}
img.qr-small { max-width: 23px; position: absolute; right: 15px; top: 8px; }
img.qr-small.qr-main { top: 7px; right: 16px; }

p.MuiTypography-root.card-title.MuiTypography-body1 span { background: #725792; width: 100%; margin: 0 auto; display: table; max-width: 600px; box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0.1); color: #fff; border-radius: 8px; padding: 5px 2px; margin-bottom: 10px; }
.MuiGrid-root.ttabs.cstm-new-tabs .box-size input { min-height: 25px; }
.MuiGrid-root.ttabs.cstm-new-tabs .MuiSelect-selectMenu { padding-top: 5px; padding-bottom: 5px;}
.MuiGrid-root.ttabs.cstm-new-tabs .money-select .MuiInputBase-root.MuiInputBase-fullWidth { min-height: 38px; }
.MuiGrid-root.ttabs.cstm-new-tabs  .MuiGrid-root.firstbox { border-radius: 40px; overflow: hidden; }
.MuiGrid-root.ttabs.cstm-new-tabs .MuiGrid-root.cstm-box-size input { text-align: center; border-radius: 40px; min-height: 25px; background: #FFFFFF; color: #000; font-size: 17px; font-weight: 400; width: 100%; padding-left: 40px; padding-right: 40px; }
.MuiGrid-root.firstbox.cstm-firstbox { border-radius: 0 !important; }
.MuiGrid-root.ttabs.cstm-new-tabs .MuiInput-root { width: 100%;}
.MuiGrid-root.ttabs.cstm-new-tabs .MuiGrid-root.cstm-box-size.fullwidthnew p.MuiTypography-root { font-size: 17px; color: #fff; text-align: center; margin-bottom: 3px; }
.agreeCheckbox svg { color: #fff; }
.MuiGrid-root.agreeCheckbox label a { color: #D07A25; }
.MuiGrid-root.fullWidth.centerbtnNew { margin: 7px auto; display: table; float: none; width: auto; padding-bottom: 28px; }
a.MuiButtonBase-root.MuiButton-root.MuiButton-text.get-started-btn.new-back-default { margin-right: 15px; background: #6E6E6E; }
// img.qr-small.GiftPage { top: 36px; }
.mb-0 { margin-bottom: 5px !important; }
.MuiGrid-root.fullWidth.pb-0.mb-0.mt-0 { margin-top: -3px; margin-bottom: 30px; float: left; width: 100%; }
.btnspace { margin-top: 0px !important; margin-bottom: 0px !important; }

.MuiGrid-root.home-tabs.bg-img:after { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAooAAAARCAYAAABaSo0lAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAylJREFUeNrs3c9rE0EUwPGZVGupqBQv/i2eevY/EP+DHgS99qJ/QEEEDx4K9j8Q9OCtRy9eRdSDogheShMbG0TxOVtDmm72x3szs2kg3w8sDcvsZN/s293XtDvxT+7v33bO3QmLuBKZXVVPTtr2wrIRlouTNbMNm7upbKbcpq6dRG3V2IGIvm1dOzFvJrqmppEXdehiiq9lC+P4iW7nko7ybJf2WJtzoyWnWptLem8SnVWlFLO1rz0SkhKNRKySzPFZczzx3JT4mFLiaw9HjNnefv5GHTfFNcF8BkhiTrbGJ4azSJSbKPdXEo9b46GMuFeIy5OXyhhNV2GZ53mnjLH+/PNh+RnWDluDqti8vMEF733R0beI+9rsbjm3Epa/YVmNrUtK/anqU2/uSyYrJ6+MdYT3dQnp1UPoFfFW5ZU23vIN2bv6cWhMt+mXYrmYeNX+ni3IfENI5Qt6aecqx0pm2jYmpp9+6Q2XpNP+vZ8+/0SZlDJZI63vIcYTyNLefvHTn9Bd9d38NjHvHK6LEcWwJuQ84zCn0ex4jxYvCiwzOed+kz5aq7qTHYXlcFyTxf2yNdYjOQAAAFCFQhEAAAAUigAAALAViu+d+j8CAQAAsDSF4tbO5gfv/VeGAgAAAGcKxfHPvbCMGA4AAACMvT4pFLd2Nr/0fG87vNx3/x+n5k/RAAAAy6eoAT965/eePt9+lrUgvH7txqVHd1/dPDruX3WTuXtO6SYLTZ8o1DobkZhmvq6YVU+M7ZPiUUwPbp/J283MUZg0EXJ9TOaZoswTYDeMd3RM9XlrnoA1ZzwtB0z0O5KYbzHzj+XMt/bJPbuZdLuLXGuOJ2XCX91xSp9gW7rOt9yTakvEtSB3ri3avTE63+K/eKDz+2NiTJLj2HRwb6y5j6ysra5/2n3x4O1wNPiTWtvx1DMAAAAoFAEAAEChCAAAAApFAAAATPntMn2Bde5CsXiA5YfjqWkAAIDzUNR2B67ioeJFKBSLCvZzrp0DAACAvq5bW718uPvyYX84GizeJ4oHg+/u3uNb/SvrG+84VgAAAHNT/DW3+PKUN2H5lavTfwIMAFGFk0uTo5cFAAAAAElFTkSuQmCC) no-repeat 50%; background-size: 93%; }
.MuiGrid-root.link-footerNew a {padding: 8px 4px !important; border-radius: 5px; border: none !important; background: #000 !important; color: #fff; font-weight: 400; float: left; margin-right: 5px; margin-bottom: 10px; }
.MuiGrid-root.link-footerNew a.active { background: #fff !important; color: #000; }
.MuiGrid-root.link-footerNew2 a { padding: 8px 4px !important; background: none !important; border: 1px #FFFFFF solid !important;}
.MuiGrid-root.link-footerNew { float: left; margin: 0px auto 0; display: table; }
.MuiGrid-root.link-footer a:last-child { border: none; }

.MuiGrid-root.textBox-option.pb-0.right-none-pad input { padding-right: 14px; }
.MuiGrid-root.common-bg > div { display: block !important; height: auto; }
.MuiGrid-root.common-bg > div main { display: none; }


button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang { background: no-repeat; border: none; margin: 0; padding: 0; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang { background: no-repeat; margin: 0; padding: 0; border: none; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang .MuiSelect-select { padding: 0; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang .MuiSelect-select img.flag { border-radius: 4px; max-width: 40px; margin-top: 2px; float: right; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang fieldset { border: none; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang svg { display: none; }


/* 25/07/2023 */
.mobileQR {  width: 40px; height: 40px; background: #fff; padding: 7px; border-radius: 3px; }
.qrGenerator .MuiPaper-root.MuiDialog-paperWidthSm { min-width: 320px; }
.qrGenerator .MuiPaper-root.MuiDialog-paperWidthSm h2 { font-size: 22px; text-align: center; text-shadow: none; font-weight: 500 !important; margin-top: 13px; }
.qrGenerator .MuiGrid-root.new-main-tab.sub.bg-dark-new-tabs input { font-size: 16px; background: #E1E1E1; border-radius: 29px; height: 45px; padding: 0 15px; text-align: center; margin-bottom: 10px; }
button.continue-modal-btn span { text-transform: uppercase; font-weight: 600 !important; }
button.continue-modal-btn { border-radius: 29px; margin: 14px 3px 20px 3px; background: #E1E1E1; padding: 8px 15px; font-size: 18px; font-weight: 500; color: #000; text-align: center; min-width: 140px; text-transform: uppercase; }
button.continue-modal-btn.active, button.continue-modal-btn:hover { background: #725792; color: #fff; }
svg.MuiSvgIcon-root.close-icon { position: absolute; right: 6px; top: 6px; cursor: pointer; }
.QRCode { width: 100%; max-width: 260px; margin: 0 auto 12px; display: table; }
.desktopQR {  width: 37px; margin-right: -27px;  border-radius: 10px 0 0 10px !important; background: #725792; padding: 7px; border-radius: 3px; }
button.desktop-QR { position: fixed !important; }
button.desktop-QR { position: absolute; right: 0px; margin-top: 120px; padding: 0; z-index: 1111; }
button.desktop-QR span { margin: 0px; padding: 0px; }
button.desktop-QR:hover { background: none !important; }
.desktopModal .MuiPaper-root.MuiDialog-paperWidthSm input { background: #E1E1E1; border-radius: 29px; height: 45px; padding: 0 15px; text-align: center; margin-bottom: 10px; }
.MuiBackdrop-root { background-color: rgba(0, 0, 0, 0.7) !important;} 

.MuiGrid-root.captcha { margin: -10px auto; text-align: center; float: none; display: table; }
.MuiGrid-root.captcha p { font-weight: 600; font-size: 25px; }
.MuiGrid-root.border-little { float: left; width: 100%; border-left: 1px solid #fff; border-right: 1px solid #fff; padding: 15px 70px; }

span.MuiTypography-root.mobile-show { display: none; float: left; margin-right: 14px; color: #fff; margin-top: 4px; font-weight: 400;}

.MuiGrid-root.mobile-space.top-space.bot-space {
	min-height: calc(100vh - 188px);
}


.MuiGrid-root.social-media-footer a { color: #fff; text-decoration: none; }
span.social-img {
    float: left;
    margin-top: -6px;
    width: 70px;
}

span.voucher-tx { position: absolute; right: -3px; background: #C7C7C7; padding: 6px 14px 9px 9px; font-size: 15px; }
.box-size.position-relative.newright-drop input { padding-left: 5px; padding-right: 58px; font-size: 15px; }
.MuiGrid-root.note-box { padding: 4px 12px 10px 12px !important; color: #fff; text-align: center; font-weight: 400; font-size: 13px; }
.MuiGrid-root.note-box p { margin: 0px; padding: 0px; }



@media (max-width: 991px) {
	.desktopQR { display: none; }
	.qrGenerator .MuiDialogContent-root { padding-left: 14px; padding-right: 14px; }
}


@media (min-width:320px) and (max-width:1055px) {
	.MuiGrid-root.login-box.MuiGrid-item { order: 1; margin-left: auto; order: unset; }
	.MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-top: 0; }
	.MuiGrid-root.menu-link { margin: 0; float: left; }
	.MuiGrid-root.right-menu.MuiGrid-item .MuiGrid-root.menu-link { float: right; }
 }

 @media (max-width:991px) {
	.MuiTypography-root.slider-main-title { text-align: center; font-size: 25px; line-height: 35px; margin-bottom: 15px; margin-top: 15px; }
	.MuiTypography-root.slider-inner-title { max-width: 100%; text-align: center; }
	.get-started-btn { margin: 20px auto 0; float: none; display: table; text-align: center; }
	.mobile-space { padding: 90px 0 50px; }
	.mobile-inner-pad { padding: 50px 0 !important; }
	.MuiGrid-root.makeStyles-bgWhite-3.mobile-inner-pad { padding: 50px 15px !important;}
	.MuiTypography-root.main-title-full { text-align: center; font-size: 32px; line-height: 45px; margin-bottom: 15px; }
	.main-title-full.text-left.ab-title { font-size: 32px; }
	.MuiTypography-root.main-title-full-inner { text-align: center; font-size: 16px; }
	.order-2 { order: 0 ; margin-bottom: 15px; }
	.center-mobile-big { max-width: 300px; }
	.none-pad { display: none; }
	.MuiGrid-root.link-footer { margin: 0 auto; width: 100%;}

	.MuiGrid-root.link-footerNew { float: none; margin: 0 auto; width: 100%;}
	.MuiGrid-root.link-footer a { margin: 0 auto; display: table; float: none; border: none; margin-top: 15px; }
	.MuiGrid-root.social-media-footer { margin: 0px auto 10px; display: table; float: none; width: auto; }
	.MuiGrid-root.agreeCheckbox > div > div > div { width: 154px !important; height: 78px; transform: scale(0.8); margin-left: -105px; }
	.MuiButton-root.get-started-btn { min-width: auto !important;}
  }

  @media ( max-width: 991px) { 
	.MuiGrid-root.search-map { max-width: 100%; position: relative; left: 0; width: calc(100% - 30px); top: 0; margin: 15px; }
	p.MuiTypography-root.card-title.MuiTypography-body1 { font-size: 25px; }
	.MuiGrid-root.tabfullWidth { padding: 20px 15px;}
	span.social-img {
		float: left;
		margin-top: -6px;
		width: auto !important;
	}
	.social-media-footer.newMedia a img { margin-right: 10px !important; margin-top: 6px !important; }
	.MuiGrid-root.social-media-footer a { margin-right: 20px; }
  }

 @media (min-width:511px) and (max-width:613px) {
	.MuiGrid-root.left-menu.MuiGrid-item, .MuiGrid-root.right-menu.MuiGrid-item { width: auto; }
 }

 @media (max-width:511px) { 
	.MuiGrid-root.left-menu.MuiGrid-item, .MuiGrid-root.right-menu.MuiGrid-item { width: auto; margin: 0 auto; float: none; display: table; }
	.MuiGrid-root.menu-link link { padding: 6px 0px; }
	.MuiGrid-root.logo-box { width: 100%; padding: 12px 0 0 !important; }
	.MuiGrid-root.pull-right.top-space { position: relative; margin-bottom: 10px; right: 0; }
	.MuiGrid-root.login-box.MuiGrid-item { margin: 0 auto; }
	.MuiGrid-root.pull-right.top-space { margin-top: 0px;}
	.MuiAppBar-positionFixed { position: relative !important ; }
	.mobile-space.top-space { padding-top: 40px; }
	.MuiGrid-root.halfwidth { width: auto; }
	.MuiGrid-root.new-main-tab.p-0.MuiGrid-item .MuiGrid-root.firstbox { margin: 0 auto; display: table; float: none; width: auto; }
	.MuiGrid-root.logo-box img { margin-bottom: 13px !important; max-width: 220px; }
	.MuiContainer-root.header-space a.MuiGrid-root.logo-box.MuiGrid-item { width: calc(100% - 64px); }
	.MuiButton-text.btn-user.active-btn.homelang { margin-top: -8px !important; }
	button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang .MuiSelect-select img.flag { margin-top: 8px !important; }
 }

 @media (max-width:767px) { 
	.MuiFormControl-root.position-select { width: 100%; margin-bottom: 5px; }
	.transaction_check.MuiFormControl-fullWidth { width: calc(100% - 154px); margin-left: 0; }
	.MuiButton-text.load-more1 { margin-left: 5px;}
	.MuiTypography-root.main-title { font-size: 25px; }
  }

  @media (max-width:360px) {  
	.MuiButton-text.language { min-width: 47px; }
	.MuiButton-text.btn-user { margin-right: 5px }
	.MuiContainer-root.header-space { padding-left: 6px; padding-right: 6px; }
	.MuiGrid-root.pull-right.top-space { margin-top: 0px; }
  }

@media (max-width:959px) {
	.MuiGrid-root.Transaction-box p { text-align: center; }
	.MuiGrid-root.Transaction-box p span { width: auto; text-align: left; float: none; }
	.MuiGrid-root.border-little { border: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px; }
	.MuiGrid-root.border-little1 { padding: 0px; }
	.social-media-footer.newMedia a + br { display: none; }
	.MuiGrid-root.link-footerNew a { width: 100%; max-width: 222px; margin: 0 auto 10px; display: table; float: none; text-align: center; }
	.homelang.mobile-flag-icon.mobileQRScan { margin: 0 auto !important; display: table; float: none; }
	span.MuiTypography-root.mobile-show { display: block; }
	img.mobileQR { margin-top: 0px !important; width: 30px; height: 30px; padding: 4px; float: left; }
	button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang.mobile-flag-icon.mobileQRScan { margin-top: 1px !important; border: 1px solid #FFFFFF; padding: 6px 30px; margin-bottom: 8px !important; }
}



p#setDescription img {
	width: 100%;
}